import { ChangeEvent, useEffect, useState } from "react";
import { deleteFile } from "../../auth/authSlice";
import { v4 as uuidv4 } from "uuid";

import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Box from "@mui/material/Box";
import Toast from "../../../components/Layout/Toast";
import { PhotoTypes } from "../types/processTypes";
import { selectProcesses, uploadFile } from "../processSlices";
import { resizeFile } from "../../../utils/imageUtility";
import { Photo, Screenshot } from "@mui/icons-material";
import { Button } from "@mui/material";

interface MedicalPassportProps {
  addLine?: Function;
  addScreenshot?: Function;
  uploadType: string;
}

const MedicalPassport: React.FC<MedicalPassportProps> = ({
  addLine,
  addScreenshot,
  uploadType,
}) => {
  // const [image, setImage] = useState("");

  const {
    success,
    loading: loadingMedicalPassport,
    error,
    uploadedPassportFile,
  } = useAppSelector(selectProcesses);
  const dispatch = useAppDispatch();
  const onChangeStand = async (event: ChangeEvent<HTMLInputElement>) => {
    const { validity, files } = event.target;
    const passportId = uuidv4();
    if (validity.valid && files && files[0]) {
      const image = (await resizeFile(files[0], 700, 700)) as File;
      if (image) {
        dispatch(
          uploadFile({
            _id: passportId as string,
            type: PhotoTypes.Passport,
            avatar: image,
          })
        );
      }
    }
  };

  useEffect(() => {
    console.log(uploadedPassportFile);
    if (
      uploadedPassportFile &&
      uploadedPassportFile.avatar &&
      uploadedPassportFile.avatar.length > 5 &&
      addLine
    ) {
      addLine("http://localhost:3322/" + uploadedPassportFile.avatar);
    }
  }, [uploadedPassportFile]);

  useEffect(() => {
    if (loadingMedicalPassport === "pending") {
      document.body.style.opacity = "0.5";
    } else {
      document.body.style.opacity = "1";
    }
  }, [loadingMedicalPassport]);

  return (
    <>
      <Paper elevation={0}>
        <input
          name="standImage"
          accept="image/*"
          onChange={onChangeStand}
          style={{ display: "none" }}
          id="icon-button-file-passport"
          type="file"
        />
        <label htmlFor="icon-button-file-passport">
          <Tooltip
            title="Add/Change Passport Scan"
            aria-label="icon-button-file-passport"
          >
            <Button
              aria-label="icon-button-file-passport"
              variant="contained"
              component="span"
              size="large"
              color="primary"
            >
              <Photo />
              {`Add Passport`}
            </Button>
          </Tooltip>
        </label>{" "}
        <Box m={1}>
          {error && <Toast severity="error">{error.message}</Toast>}
          {success && <Toast severity="success">{success.message}</Toast>}
        </Box>
      </Paper>
    </>
  );
};

export default MedicalPassport;
