import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchMedicalPayments,
  selectProcesses,
  removeMedicalPayment,
} from "../process/processSlices";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import { NavLink as RouterLink } from "react-router-dom";

import {
  changePageTitle,
  selectPreference,
} from "../preferences/preferencesSlice";
//import Avatar from "@mui/material/Avatar";
import {
  IconButton,
  Stack,
  Button,
  Typography,
  Tooltip,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";

import {
  StyledTableCell,
  StyledTableCellSudan,
  StyledTableRow,
} from "../../styles/tableStyles";
import TableSkeleton from "../../components/Layout/TableSkeleton";
import Paging from "../../components/Layout/Paging";
import Toast from "../../components/Layout/Toast";

export const Medicals = () => {
  const dispatch = useAppDispatch();
  const {
    medicalPaymentsWithCount: { medicalPayments, totalCount },
    loading,
    success,
    error,
  } = useAppSelector(selectProcesses);
  //const { MedicalPayment } = useAppSelector(selectAuth);
  const { searchText } = useAppSelector(selectPreference);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedMedicalPaymentId, setSelectedMedicalPaymentId] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = (id: string) => {
    setSelectedMedicalPaymentId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const DeleteMedicalPayment = () => {
    setOpen(false);
    dispatch(removeMedicalPayment(selectedMedicalPaymentId));
  };
  useEffect(() => {
    dispatch(changePageTitle("Medicals List"));

    const skipRows = currentPage * rowsPerPage;

    // dispatch(
    //   fetchMedicalPayments({
    //     searchText,
    //     skip: skipRows,
    //     take: rowsPerPage,
    //   })
    // );
  }, [dispatch, searchText, currentPage, rowsPerPage]); //

  useEffect(() => {
    setTotal(totalCount as number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount]);

  return (
    <>
      <Box
        sx={{
          m: { xs: 0, md: 1 },
          px: { xs: 0, md: 2 },
        }}
      >
        {medicalPayments && medicalPayments.length > 0 && (
          <>
            <Grid container justifyContent="flex-start" sx={{ mt: 1 }}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="simple table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCellSudan>S.No</StyledTableCellSudan>
                      <StyledTableCellSudan>
                        MedicalPayment No.
                      </StyledTableCellSudan>
                      <StyledTableCellSudan>Employer Name</StyledTableCellSudan>
                      <StyledTableCellSudan>Employer Id</StyledTableCellSudan>
                      <StyledTableCellSudan>Actions</StyledTableCellSudan>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {loading === "pending" ? (
                      <TableSkeleton numRows={5} numColumns={4} />
                    ) : (
                      medicalPayments.map((row, index) => (
                        <StyledTableRow key={row._id}>
                          <StyledTableCell component="th" scope="row">
                            {currentPage * rowsPerPage + index + 1}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Button
                              color="primary"
                              component={RouterLink}
                              to={`/app/medicalPayment/${row._id}`}
                              size="small"
                            >
                              {row.totalAmount}
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Button
                              color="primary"
                              component={RouterLink}
                              to={`/app/medicalPayment/${row._id}`}
                              size="small"
                            >
                              {row.status}
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Button
                              color="primary"
                              component={RouterLink}
                              to={`/app/medicalPayment/${row._id}`}
                              size="small"
                            >
                              {row.screenshotLink}
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Stack
                              direction="row"
                              spacing={2}
                              alignItems="center"
                            >
                              <IconButton
                                color="primary"
                                component={RouterLink}
                                to={"/app/medicalPayment/" + row._id}
                                size="large"
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                color="secondary"
                                onClick={() =>
                                  handleClickOpen(row._id as string)
                                }
                                size="large"
                              >
                                <Delete />
                              </IconButton>
                            </Stack>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <br />
            {success && <Toast severity="success">{success.message}</Toast>}
            {error && <Toast severity="error">{error.message}</Toast>}
            <Stack spacing={1}>
              <Paging
                total={total}
                rowsPerPage={rowsPerPage}
                currentPage={currentPage}
                setRowsPerPage={setRowsPerPage}
                setCurrentPage={setCurrentPage}
              />
              <Typography variant="h6" color="textPrimary" component="div">
                Number of MedicalPayments: {total}
              </Typography>
            </Stack>
          </>
        )}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Taking this action is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button onClick={DeleteMedicalPayment}>Continue</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
