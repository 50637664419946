import { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, useTheme } from "@mui/material";
import { FormikProps } from "formik";
import { Visa } from "../types/processTypes";
import { Helmet } from "react-helmet";
import { Add, Error, List, Summarize } from "@mui/icons-material";
import { Medicals } from "../Medicals";
import { MedicalEntry } from "../MedicalEntry";
import { StyledTab } from "../../../styles/componentStyled";

interface VisaTabProps {
  formikData: FormikProps<Visa>;
}
export const MedicalTabs = () => {
  const theme = useTheme();
  const [tabValue, setValue] = useState("0");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <>
      <Helmet>
        <title>Medicals | EnjazPlus</title>
      </Helmet>

      <TabContext value={tabValue}>
        <Box
          sx={{
            my: 1,
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "4px",
          }}
        >
          <TabList
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="lab API tabs example"
            textColor="primary"
          >
            <StyledTab icon={<List />} label="Ordered" value="0" />
            <StyledTab icon={<Add />} label="Add New" value="1" />
            <StyledTab icon={<Error />} label="Failed" value="2" />
            <StyledTab icon={<Summarize />} label="Summary" value="3" />
          </TabList>
        </Box>
        <TabPanel
          sx={{
            py: 0,
            px: 1,
            backgroundColor: theme.palette.common.white,
            border: `solid 1px ${theme.palette.primary.main}`,
            minHeight: "80vh",
            borderRadius: "10px",
          }}
          value="0"
        >
          <Medicals />
        </TabPanel>
        <TabPanel
          sx={{
            py: 0,
            px: 1,
            backgroundColor: theme.palette.common.white,
            border: `solid 1px ${theme.palette.primary.main}`,
            minHeight: "80vh",
            borderRadius: "10px",
          }}
          value="1"
        >
          <MedicalEntry />
        </TabPanel>
        <TabPanel
          sx={{
            py: 0,
            px: 1,
            backgroundColor: theme.palette.common.white,
            border: `solid 1px ${theme.palette.primary.main}`,
            minHeight: "80vh",
            borderRadius: "10px",
          }}
          value="2"
        ></TabPanel>
        <TabPanel
          sx={{
            py: 0,
            px: 1,
            backgroundColor: theme.palette.common.white,
            border: `solid 1px ${theme.palette.primary.main}`,
            minHeight: "80vh",
            borderRadius: "10px",
          }}
          value="3"
        ></TabPanel>
      </TabContext>
    </>
  );
};
