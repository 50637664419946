import * as XLSX from "xlsx";
import { Employee, Nationalities } from "../types/processTypes";
import { Button, Stack, Tooltip } from "@mui/material";
import { Upload } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { differenceInMonths, format } from "date-fns";
import { Company } from "../../setups/types/setupTypes";
import { AuthUser } from "../../auth/types/authType";
import countries from "./countries";

interface ExportProps {
  selectedEmployees: Employee[];
  company: Company;
  user: AuthUser;
}

function ExportToExcel({ selectedEmployees, company, user }: ExportProps) {
  const { t: te } = useTranslation("enums");

  const handleFullDownload = () => {
    // flatten object like this {id: 1, title:'', category: ''};
    const rows = selectedEmployees.map((employee) => ({
      fullName: employee.givenName + " " + employee.surName,
      passportNumber: employee.passportNumber,
      sex: te(employee.sex as string),
      placeOfBirth: employee.placeOfBirth,
      dateOfBirth: format(
        new Date(employee.dateOfBirth as Date),
        "dd-MMM-yyyy"
      ).toUpperCase(),
      age: Math.floor(
        differenceInMonths(new Date(), new Date(employee.dateOfBirth as Date)) /
          12
      ),
      passportIssueDate: format(
        new Date(employee.passportIssueDate as Date),
        "dd-MMM-yyyy"
      ).toUpperCase(),
      placeOfIssue: employee.placeOfIssue,
      passportExpiryDate: format(
        new Date(employee.passportExpiryDate as Date),
        "dd-MMM-yyyy"
      ).toUpperCase(),
      religion: te(employee.additionalData?.religion as string),
      maritalStatus: te(employee.additionalData?.maritalStatus as string),
      appliedProfession: te(
        employee.additionalData?.appliedProfession as string
      ),
      telephone: employee.address?.telephone,
      contactName: employee.contactPerson?.displayName,
      contactTelephone: employee.contactPerson?.address?.telephone,
      arabic: te(employee.education?.arabicLanguage as string),
      english: te(employee.education?.englishLanguage as string),
      referenceNumber: employee.education?.yearCompleted,

      visaNumber: employee.visa?.visaNumber,
      sponsorName: employee.visa?.fullName,
      sponsorId: employee.visa?.passportNumber,
      sponsorTelephone: employee.visa?.address?.telephone,
      sponsorCity: employee.visa?.address?.city,
      applicationNumber: employee.embassyProcess?.enjazNumber,
    }));
    // console.log(selectedEmployees);
    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows);

    worksheet["!cols"] = [
      { wch: 30 },
      { wch: 15 },
      { wch: 10 },
      { wch: 20 },
      { wch: 15 },
      { wch: 5 },
      { wch: 15 },
      { wch: 20 },
      { wch: 15 },
      { wch: 10 },
      { wch: 10 },
      { wch: 15 },
      { wch: 15 },
      { wch: 30 },
      { wch: 20 },
      { wch: 10 },
      { wch: 10 },
      { wch: 20 },
      { wch: 15 },
      { wch: 30 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    XLSX.utils.book_append_sheet(workbook, worksheet, "Employees");

    // customize header names
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          "Full Name",
          "Passport No.",
          "Sex",
          "Place Of Birth",
          "Birth Date",
          "Age",
          "Issue Date",
          "Place Of Issue",
          "Expiry Date",
          "Religion",
          "Marital Status",
          "Profession",
          "Tel.",
          "Contact Name",
          "Contact Tel.",
          "Arabic",
          "English",
          "Reference No.",
          "Visa No.",
          "Sponsor Name",
          "Sponsor Id",
          "Sponsor Tel.",
          "Sponsor City",
          "Application No.",
        ],
      ],
      { origin: "A1" }
    );

    XLSX.writeFile(workbook, new Date().toLocaleDateString() + "_Export.xlsx", {
      compression: true,
    });
  };
  const handleTasheerDownload = () => {
    // flatten object like this {id: 1, title:'', category: ''};
    let companyTel = "";
    if (
      company &&
      company.address &&
      company.address.telephone &&
      company.address.telephone.length > 0
    ) {
      if (company.address.telephone.length > 9) {
        companyTel = company.address.telephone.slice(-9);
      } else {
        companyTel = company.address.telephone;
      }
    }
    const rows = selectedEmployees.map((employee) => ({
      applicationNumber: employee.embassyProcess?.enjazNumber,
      firstName:
        employee.givenName && employee.givenName.split(" ").length > 0
          ? employee.givenName.split(" ")[0]
          : "",
      secondName:
        employee.givenName && employee.givenName.split(" ").length > 1
          ? employee.givenName.split(" ")[1]
          : "",
      lastName: employee.surName,
      passportNumber: employee.passportNumber,

      dateOfBirth: new Date(
        format(new Date(employee.dateOfBirth as Date), "dd/MMM/yyyy")
      ),

      nationality:
        employee.additionalData?.nationality === Nationalities.sudanese
          ? "Sudan"
          : "Ethiopia",
      passportIssueDate: new Date(
        format(new Date(employee.passportIssueDate as Date), "dd/MMM/yyyy")
      ),
      sex: te(employee.sex as string),
      placeOfIssue: employee.placeOfIssue,

      passportExpiryDate: new Date(
        format(new Date(employee.passportExpiryDate as Date), "dd/MMM/yyyy")
      ),

      telephone:
        companyTel !== ""
          ? parseInt(companyTel)
          : employee.address?.telephone
          ? parseInt(employee.address?.telephone)
          : "",

      email: user.email,
    }));
    // console.log(selectedEmployees);
    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet(rows);

    worksheet["!cols"] = [
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 15 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 15 },
      { wch: 10 },
      { wch: 15 },
      { wch: 20 },
    ];
    XLSX.utils.book_append_sheet(workbook, worksheet, "Bio_Applicant_Details");

    //customize header names
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          "E.No",
          "First Name*",
          "Second Name",
          "Last Name*",
          "Passport Number*",
          "Date of Birth*",
          "Nationality*",
          "Date of Issue*",
          "Gender*",
          "Place of Issue*",
          "Expiry Date*",
          "Applicant Mobile No.*",
          "Email ID*",
        ],
      ],
      { origin: "A1", cellDates: true }
    );

    // console.log(countries);
    const countries2 = countries.map((country) => ({
      cou: country,
    }));
    const hiddenWorksheet = XLSX.utils.json_to_sheet(countries2, {
      skipHeader: true,
    });
    XLSX.utils.book_append_sheet(workbook, hiddenWorksheet, "hiddenSheet");

    // if the metadata does not exist for the sheet, create it
    if (!workbook.Workbook) workbook.Workbook = {};
    if (!workbook.Workbook.Sheets) workbook.Workbook.Sheets = [];
    if (!workbook.Workbook.Sheets[1]) workbook.Workbook.Sheets[1] = {};

    // set visibility
    workbook.Workbook.Sheets[1].Hidden = 1;

    XLSX.writeFile(
      workbook,
      new Date().toLocaleDateString() +
        "_" +
        new Date().getMilliseconds() +
        "_Tasheer.xls",
      {
        compression: true,
      }
    );
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Button
        variant="outlined"
        onClick={handleTasheerDownload}
        sx={{
          backgroundImage: "url(/tasheer.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100px 40px",
          backgroundPositionX: "right",
          paddingRight: "110px",
        }}
      >
        <Upload />
        Export for
      </Button>
      <Button
        color="secondary"
        variant="contained"
        onClick={handleFullDownload}
        size="medium"
      >
        <Upload /> Full Export
      </Button>
    </Stack>
  );
}

export default ExportToExcel;
