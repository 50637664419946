import { AuthUser, UploadGraphQLResult } from "../../auth/types/authType";
import { Address, Company, ContactPerson } from "../../setups/types/setupTypes";

export type EmployeeArgs = {
  skip?: number;
  take?: number;
  status?: EmployeeStatus;
  searchText?: string;
  refreshList?: string;
  lastUpdated?: Date;
  link?: string;
  agencyId?: string;
  agentId?: string;
};

export type ShareByEmail = {
  email?: string;
  link?: string;
};

export type VisaArgs = {
  skip?: number;
  take?: number;
  searchText?: string;
  refreshList?: string;
  lastUpdated?: Date;
};
export type EmployeesWithCount = {
  totalCount: number;
  employees: Employee[];
};
export type VisasWithCount = {
  totalCount: number;
  visas: Visa[];
};
export type MedicalPaymentsWithCount = {
  totalCount: number;
  medicalPayments: MedicalPayment[];
};
export type DelResult = {
  affectedRows?: string;
};

export enum Sex {
  male = "male",
  female = "female",
}
export enum Nationalities {
  ethiopian = "ethiopian",
  sudanese = "sudanese",
  kenyan = "kenyan",
  yemeni = "yemeni",
  somali = "somali",
  eritrean = "eritrean",
  afghan = "afghan",
  albanian = "albanian",
  algerian = "algerian",
  american = "american",
  andorran = "andorran",
  angolan = "angolan",
  antiguans = "antiguans",
  argentinean = "argentinean",
  armenian = "armenian",
  australian = "australian",
  austrian = "austrian",
  azerbaijani = "azerbaijani",
  bahamian = "bahamian",
  bahraini = "bahraini",
  bangladeshi = "bangladeshi",
  barbadian = "barbadian",
  barbudans = "barbudans",
  batswana = "batswana",
  belarusian = "belarusian",
  belgian = "belgian",
  belizean = "belizean",
  beninese = "beninese",
  bhutanese = "bhutanese",
  bolivian = "bolivian",
  bosnian = "bosnian",
  brazilian = "brazilian",
  british = "british",
  bruneian = "bruneian",
  bulgarian = "bulgarian",
  burkinabe = "burkinabe",
  burmese = "burmese",
  burundian = "burundian",
  cambodian = "cambodian",
  cameroonian = "cameroonian",
  canadian = "canadian",
  capeVerdean = "capeVerdean",
  centralAfrican = "centralAfrican",
  chadian = "chadian",
  chilean = "chilean",
  chinese = "chinese",
  colombian = "colombian",
  comoran = "comoran",
  congolese = "congolese",
  costaRican = "costaRican",
  croatian = "croatian",
  cuban = "cuban",
  cypriot = "cypriot",
  czech = "czech",
  danish = "danish",
  djibouti = "djibouti",
  dominican = "dominican",
  dutch = "dutch",
  eastTimorese = "eastTimorese",
  ecuadorean = "ecuadorean",
  egyptian = "egyptian",
  emirian = "emirian",
  equatorialGuinean = "equatorialGuinean",
  estonian = "estonian",
  fijian = "fijian",
  filipino = "filipino",
  finnish = "finnish",
  french = "french",
  gabonese = "gabonese",
  gambian = "gambian",
  georgian = "georgian",
  german = "german",
  ghanaian = "ghanaian",
  greek = "greek",
  grenadian = "grenadian",
  guatemalan = "guatemalan",
  guineaBissauan = "guineaBissauan",
  guinean = "guinean",
  guyanese = "guyanese",
  haitian = "haitian",
  herzegovinian = "herzegovinian",
  honduran = "honduran",
  hungarian = "hungarian",
  icelander = "icelander",
  indian = "indian",
  indonesian = "indonesian",
  iranian = "iranian",
  iraqi = "iraqi",
  irish = "irish",
  italian = "italian",
  ivorian = "ivorian",
  jamaican = "jamaican",
  japanese = "japanese",
  jordanian = "jordanian",
  kazakhstani = "kazakhstani",
  kittianAndNevisian = "kittianAndNevisian",
  kuwaiti = "kuwaiti",
  kyrgyz = "kyrgyz",
  laotian = "laotian",
  latvian = "latvian",
  lebanese = "lebanese",
  liberian = "liberian",
  libyan = "libyan",
  liechtensteiner = "liechtensteiner",
  lithuanian = "lithuanian",
  luxembourger = "luxembourger",
  macedonian = "macedonian",
  malagasy = "malagasy",
  malawian = "malawian",
  malaysian = "malaysian",
  maldivan = "maldivan",
  malian = "malian",
  maltese = "maltese",
  marshallese = "marshallese",
  mauritanian = "mauritanian",
  mauritian = "mauritian",
  mexican = "mexican",
  micronesian = "micronesian",
  moldovan = "moldovan",
  monacan = "monacan",
  mongolian = "mongolian",
  moroccan = "moroccan",
  mosotho = "mosotho",
  motswana = "motswana",
  mozambican = "mozambican",
  namibian = "namibian",
  nauruan = "nauruan",
  nepalese = "nepalese",
  newZealander = "newZealander",
  niVanuatu = "niVanuatu",
  nicaraguan = "nicaraguan",
  nigerien = "nigerien",
  northKorean = "northKorean",
  northernIrish = "northernIrish",
  norwegian = "norwegian",
  omani = "omani",
  pakistani = "pakistani",
  palauan = "palauan",
  palestinian = "palestinian",
  panamanian = "panamanian",
  papuaNewGuinean = "papuaNewGuinean",
  paraguayan = "paraguayan",
  peruvian = "peruvian",
  polish = "polish",
  portuguese = "portuguese",
  qatari = "qatari",
  romanian = "romanian",
  russian = "russian",
  rwandan = "rwandan",
  saintLucian = "saintLucian",
  salvadoran = "salvadoran",
  samoan = "samoan",
  sanMarinese = "sanMarinese",
  saoTomean = "saoTomean",
  saudi = "saudi",
  scottish = "scottish",
  senegalese = "senegalese",
  serbian = "serbian",
  seychellois = "seychellois",
  sierraLeonean = "sierraLeonean",
  singaporean = "singaporean",
  slovakian = "slovakian",
  slovenian = "slovenian",
  solomonIslander = "solomonIslander",
  southAfrican = "southAfrican",
  southKorean = "southKorean",
  spanish = "southKorean",
  sriLankan = "sriLankan",
  surinamer = "surinamer",
  swazi = "swazi",
  swedish = "swedish",
  swiss = "swiss",
  syrian = "syrian",
  taiwanese = "taiwanese",
  tajik = "tajik",
  tanzanian = "tanzanian",
  thai = "thai",
  togolese = "togolese",
  tongan = "tongan",
  trinidadianorTobagonian = "trinidadianorTobagonian",
  tunisian = "tunisian",
  turkish = "turkish",
  tuvaluan = "tuvaluan",
  ugandan = "ugandan",
  ukrainian = "ukrainian",
  uruguayan = "uruguayan",
  uzbekistani = "uzbekistani",
  venezuelan = "venezuelan",
  vietnamese = "vietnamese",
  welsh = "welsh",
  zambian = "zambian",
  zimbabwean = "zimbabwean",
}
export enum CurrencyTypes {
  saudi_riyal = "saudi_riyal",
  qatar_riyal = "qatar_riyal",
  kuwait_dinar = "kuwait_dinar",
  emirate_dirham = "emirate_dirham",
  jordan_dinar = "jordan_dinar",
  lebanon_lire = "lebanon_lire",
  ethiopia_birr = "ethiopia_birr",
}
export type Education = {
  arabicLanguage?: LanguageExperience;
  englishLanguage?: LanguageExperience;
  educationLevel?: EducationLevel;
  fieldOfStudy?: string;
  yearCompleted?: string;
  skills?: Skills[];
  educationQG?: string;
  remarks?: string;
};

export type EmbassyProcess = {
  enjazNumber?: string;
  submissionDate?: Date;
  stamped?: boolean;
  stampedDate?: Date;
  cancelled?: boolean;
  canceledDate?: Date;
  remarks?: string;
};

export type FlightProcess = {
  flightDateTime?: Date;
  ticketNumber?: string;
  flightNumber?: string;
  ticketAmount?: number;
  ticketCity?: string;
  departed?: boolean;
  arrivedDateTime?: Date;
  remarks?: string;
};

export type AdditionalData = {
  nationality?: Nationalities;
  pastNationality?: Nationalities;
  maritalStatus?: MaritalStatus;
  religion?: ReligionTypes;
  appliedProfession?: ProfessionalTypes;
  appliedCountry?: CountriesList;
  contractPeriod?: ExperienceYears;
  complexion?: Complexion;
  salary?: number;
  currency?: CurrencyTypes;
  numberOfChildren?: number;
  height?: number;
  weight?: number;
  documentReceivedDate?: Date;
  notes?: string;
  fullNameAmharic?: string;
  lmisId?: string;
  lmisPassword?: string;
  labourId?: string;
  discontinued?: boolean;
  discontinuedDate?: Date;
  discontinuedRemark?: string;
  afterFlightStatus?: AfterFlightStatus;
  afterFlightStatusDate?: Date;
  afterFlightStatusRemark?: string;
};

export enum LanguageExperience {
  poor = "poor",
  fair = "fair",
  good = "good",
  fluent = "fluent",
}

export enum EducationLevel {
  grade_eight_complete = "grade_eight_complete",
  secondary_complete = "secondary_complete",
  vocational_complete = "vocational_complete",
  college_complete = "college_complete",
  post_graduate = "post_graduate",
  other_education = "other_education",
}

export type MedicalCenter = {
  displayName?: string;
  address?: string;
  telephone?: string;
};

export type MedicalDetail = {
  _id?: string;
  by?: MedicalBy;
  byLinkOrName?: string;
  status?: MedicalStatus;
  slipLink?: string;
  expiryDate?: Date;
  center?: MedicalCenter;
  notes?: string;
};

export enum MedicalBy {
  passport = "passport",
  wafidLink = "wafidLink",
  bySearch = "bySearch",
}

export enum MedicalStatus {
  new = "new",
  paid = "paid",
  canceled = "canceled",
}

export type MedicalPayment = {
  _id?: string;
  user?: AuthUser;
  orderedAt?: Date;
  details?: [MedicalDetail];
  screenshotLink?: string;
  transferReason?: string;
  totalAmount?: number;
  status?: MedicalPaymentStatus;
  remarks?: string;
};

export enum MedicalPaymentStatus {
  new = "new",
  screenshotAttached = "screenshotAttached",
  verified = "verified",
}

export type Visa = {
  _id?: string;
  visaNumber: string;
  fullName: string;
  fullNameAmharic?: string;
  cityAmharic?: string;
  passportNumber?: string;
  visaQty?: number;
  contractNumber?: string;
  fileNumber?: string;
  bankNumber?: string;
  visaDate?: Date;
  visaDateArabic?: string;
  wekalaNumber?: string;
  wekalaDate?: string;
  profession?: ProfessionalTypes;
  salary?: number;
  currency?: CurrencyTypes;
  religion?: ReligionTypes;
  ageCategory?: AgeCategory;
  complexion?: Complexion;
  visaType?: VisaTypes;
  status?: VisaStatus;
  firstTime?: Boolean;
  goodLooking?: Boolean;
  writeRead?: Boolean;
  remarks?: string;
  address?: Address;
  owner?: Company;
  prospects?: [Company];
  assignedEmployees?: [string];
  createdBy?: string;
  modifiedBy?: string;
  createdAt?: Date;
  modifiedAt?: Date;
};

export enum ProfessionalTypes {
  housemaid = "housemaid",
  house_worker = "house_worker",
  driver = "driver",
  baby_sitter = "baby_sitter",
  nanny = "nanny",
  cooker = "cooker",
  nurse = "nurse",
  farm_worker = "farm_worker",
  other_profession = "other_profession",
}

export enum ReligionTypes {
  muslim = "muslim",
  non_muslim = "non_muslim",
  christian = "christian",
  other_religion = "other_religion",
}

export enum AgeCategory {
  bet2125 = "bet2125",
  bet2630 = "bet2630",
  bet3135 = "bet3135",
  bet3640 = "bet3640",
  bet4145 = "bet4145",
}

export enum Complexion {
  brown = "brown",
  black = "black",
  blue = "blue",
  acceptable = "acceptable",
}

export type WorkExperience = {
  _id?: string;
  country?: CountriesList;
  profession?: ProfessionalTypes;
  years?: ExperienceYears;
};

export enum Skills {
  driving = "driving",
  baby_sitting = "baby_sitting",
  children_care = "children_care",
  tutoring_children = "tutoring_children",
  washing_ironing_clothes = "washing_ironing_clothes",
  dusting_cleaning = "dusting_cleaning",
  washing_dishes = "washing_dishes",
  cooking = "cooking",
  arabic_cooking = "arabic_cooking",
  sewing = "sewing",
  computer = "computer",
  decorating = "decorating",
  other_skills = "other_skills",
}
export enum RequiredDocuments {
  passport = "passport",
  photo = "photo",
  id_card = "id_card",
  emergency_person_photo = "emergency_person_photo",
  emergency_person_id_card = "emergency_person_id_card",
  finger_print = "finger_print",
  medical = "medical",
  grade_eight_certificate = "grade_eight_certificate",
  coc_certificate = "coc_certificate",
  insurance = "insurance",
  abroad_job_agreement = "abroad_job_agreement",
  trip_orientation = "trip_orientation",
}
export enum CountriesList {
  saudi_arabia = "saudi_arabia",
  uae = "uae",
  kuwait = "kuwait",
  qatar = "qatar",
  lebanon = "lebanon",
  bahrain = "bahrain",
  jordan = "jordan",
  yemen = "yemen",
  syria = "syria",
  oman = "oman",
  ethiopia = "ethiopia",
  kenya = "kenya",
  sudan = "sudan",
  somalia = "somalia",
  eritrea = "eritrea",
  egypt = "egypt",
}

export enum ExperienceYears {
  one = "one",
  two = "two",
  three = "three",
  four = "four",
  five = "five",
  six = "six",
  seven = "seven",
  eight = "eight",
  nine = "nine",
  ten = "ten",
}

export enum VisaStatus {
  new = "new",
  available = "available",
  rejected = "rejected",
  employee = "employee",
  arrived = "arrived",
  processing = "processing",
  keep_ready = "keep_ready",
  visa_ready = "visa_ready",
  on_medical = "on_medical",
  on_foreign_affairs = "on_foreign_affairs",
}
export enum VisaTypes {
  work = "work",
  visit = "visit",
  transit = "transit",
  residence = "residence",
  umrah = "umrah",
  hajj = "hajj",
  diplomacy = "diplomacy",
  other = "other",
}
export type ShortLink = {
  _id?: string;
  link?: string;

  employeeIds?: string[];
  employees?: [Employee];
  expiredAt?: Date;
  company?: Company;
};
export type ShareLink = {
  employeeIds: [string];
  companyId: string;
};
export type Employee = {
  _id?: string;
  givenName?: string;
  surName?: string;
  passportNumber?: string;
  sex?: Sex;
  status?: EmployeeStatus;
  placeOfBirth?: string;
  placeOfIssue?: string;
  dateOfBirth?: Date;
  passportIssueDate?: Date;
  passportExpiryDate?: Date;
  documents?: [RequiredDocuments];
  additionalData?: AdditionalData;
  address?: Address;
  contactPerson?: ContactPerson;
  education?: Education;
  embassyProcess?: EmbassyProcess;
  flight?: FlightProcess;
  workExperience?: [WorkExperience];
  facePhoto?: string;
  standPhoto?: string;
  passportScan?: string;
  owner?: Company;
  visa?: Visa;
  prospects?: [Company];
  createdBy?: string;
  modifiedBy?: string;
  createdAt?: Date;
  modifiedAt?: Date;
};
export enum PhotoTypes {
  Face = "Face",
  Stand = "Stand",
  Passport = "Passport",
  Header = "Header",
  Bottom = "Bottom",
  User = "User",
}
export enum MaritalStatus {
  single = "single",
  married = "married",
  divorced = "divorced",
  widow = "widow",
  separated = "separated",
}
export enum AfterFlightStatus {
  on_good_condition = "on_good_condition",
  lost = "lost",
  returned = "returned",
  has_complain = "has_complain",
  contract_completed = "contract_completed",
}
export enum EmployeeStatus {
  all = "all",
  new = "new",
  selected = "selected",
  contract_process = "contract_process",
  medical_process = "medical_process",
  embassy_process = "embassy_process",
  ossc_process = "ossc_process",
  labour_process = "labour_process",
  discontinued = "discontinued",
  flight_process = "flight_process",
  flight_booked = "flight_booked",
  traveled = "traveled",
  on_good_condition = "on_good_condition",
  lost = "lost",
  returned = "returned",
  has_complain = "has_complain",
  contract_completed = "contract_completed",
}
export enum StatusFilter {
  all = "all",
  new = "new",
  selected = "selected",
  contract_process = "contract_process",
  medical_process = "medical_process",
  embassy_process = "embassy_process",
  ossc_process = "ossc_process",
  labour_process = "labour_process",
  discontinued = "discontinued",
  visa_canceled = "visa_canceled",
  flight_process = "flight_process",
  flight_booked = "flight_booked",
  traveled = "traveled",
  on_good_condition = "on_good_condition",
  lost = "lost",
  returned = "returned",
  has_complain = "has_complain",
}

export type ProcessState = {
  employeesWithCount: EmployeesWithCount;
  visasWithCount: VisasWithCount;
  medicalPaymentsWithCount: MedicalPaymentsWithCount;
  selectedEmployee: Employee;
  sharedLinks: ShortLink[];
  selectedVisa: Visa;
  selectedMedicalPayment: MedicalPayment;
  uploadedPassportFile: UploadGraphQLResult;
  uploadedScreenshotFile: UploadGraphQLResult;
  selectedShortLink: ShortLink;
  loading: "idle" | "pending";
  currentRequestId: string | undefined;
  success: any;
  error: any;
};
