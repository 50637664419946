import * as Yup from "yup";

const passportNumRegExp = /^([ABCEabce]{1,1}[KPQkpq]{1,1}[0-9]{5,10})$/;
const givenNameRegExp = /^([A-Za-z]{2,15})+ [A-Za-z]{2,15}$/;
const givenNameComRegExp = /^[A-Za-z\s]{2,35}$/; // /^([A-Za-z]{2,15})+ [A-Za-z]{2,15}$/;
const surNameRegExp = /^[A-Za-z]{2,25}$/;
const surNameRegExpCom = /^[A-Za-z\s]{2,35}$/;

const passportNumComRegExp = /^[A-Za-z0-9]{2,25}$/;
const passportNumberCom = Yup.string()
  .required()
  .matches(passportNumComRegExp, {
    message: "Incorrect Passport Number",
  });

const passportNumber = Yup.string().required().matches(passportNumRegExp, {
  message: "Incorrect Passport Number",
});
const givenName = Yup.string().required().matches(givenNameRegExp, {
  message: "Incorrect Given Name",
});
const givenNameCom = Yup.string().required().matches(givenNameComRegExp, {
  message: "Incorrect Given Name",
});
const surName = Yup.string().required().matches(surNameRegExp, {
  message: "Incorrect Sur Name",
});
const surNameCom = Yup.string().required().matches(surNameRegExpCom, {
  message: "Incorrect Sur Name",
});
const placeOfBirth = Yup.string().required().min(2).max(25);
const placeOfIssue = Yup.string().required().min(2).max(25);
const applicationNumberRegExp = /^([\s]{0,2}[E]{1,1}[0-9]{8,10}[\s]{0,2})$/;
const applicationNumber = Yup.string().matches(applicationNumberRegExp, {
  message: "Incorrect Application Number: add 'E' before the number",
  excludeEmptyString: true,
});

const visaNumberRegExp = /^([\s]{0,2}[0-9]{9,10}[\s]{0,2})$/;
const visaNumber = Yup.string().matches(visaNumberRegExp, {
  message: "Incorrect Visa Number",
  excludeEmptyString: true,
});
const employerIdRegExp = /^([\s]{0,2}[0-9]{1,10}[\s]{0,2})$/;
const employerId = Yup.string().matches(employerIdRegExp, {
  message: "Incorrect Employer Id Number",
  excludeEmptyString: true,
});

export const createEmployeeSchema = Yup.object({
  passportNumber,
  givenName,
  surName,
  placeOfBirth,
  placeOfIssue,
  embassyProcess: Yup.object({
    enjazNumber: applicationNumber,
  }).nullable(true),
  visa: Yup.object({
    visaNumber: visaNumber,
    passportNumber: employerId,
  }).nullable(true),
});
export const createCommissionSchema = Yup.object({
  passportNumber: passportNumberCom,
  givenName: givenNameCom,
  surName: surNameCom,
  placeOfBirth,
  placeOfIssue,
  embassyProcess: Yup.object({
    enjazNumber: applicationNumber,
  }).nullable(true),
  visa: Yup.object({
    visaNumber: visaNumber,
    passportNumber: employerId,
  }).nullable(true),
});

export const createVisaSchema = Yup.object({
  visaNumber,
});

export const createMedicalPaymentSchema = Yup.object({
  byLinkOrName: placeOfBirth,
});
