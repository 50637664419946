import { ChangeEvent, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Box from "@mui/material/Box";
import Toast from "../../../components/Layout/Toast";
import { PhotoTypes } from "../types/processTypes";
import { selectProcesses, uploadFile } from "../processSlices";
import { resizeFile } from "../../../utils/imageUtility";
import { Photo, Screenshot } from "@mui/icons-material";
import { Button } from "@mui/material";

interface MedicalScreenshotProps {
  addLine?: Function;
  addScreenshot?: Function;
  uploadType: string;
}

const MedicalScreenshot: React.FC<MedicalScreenshotProps> = ({
  addScreenshot,
}) => {
  // const [image, setImage] = useState("");

  const {
    success,
    loading: loadingMedicalScreenshot,
    error,
    uploadedScreenshotFile,
  } = useAppSelector(selectProcesses);
  const dispatch = useAppDispatch();
  const onChangeStand2 = async (event: ChangeEvent<HTMLInputElement>) => {
    const { validity, files } = event.target;
    const passportId = uuidv4();
    if (validity.valid && files && files[0]) {
      const image = (await resizeFile(files[0], 700, 700)) as File;
      if (image) {
        dispatch(
          uploadFile({
            _id: passportId as string,
            type: PhotoTypes.Stand,
            avatar: image,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (
      uploadedScreenshotFile &&
      uploadedScreenshotFile.avatar &&
      uploadedScreenshotFile.avatar.length > 5 &&
      addScreenshot
    ) {
      addScreenshot("http://localhost:3322/" + uploadedScreenshotFile.avatar);
    }
  }, [uploadedScreenshotFile]);
  useEffect(() => {
    if (loadingMedicalScreenshot === "pending") {
      document.body.style.opacity = "0.5";
    } else {
      document.body.style.opacity = "1";
    }
  }, [loadingMedicalScreenshot]);

  return (
    <>
      <Paper elevation={0}>
        <input
          name="standImage2"
          accept="image/*"
          onChange={onChangeStand2}
          style={{ display: "none" }}
          id="icon-button-file-stand"
          type="file"
        />
        <label htmlFor="icon-button-file-stand">
          <Tooltip
            title="Add/Change Passport Scan"
            aria-label="icon-button-file-stand"
          >
            <Button
              aria-label="icon-button-file-stand"
              variant="contained"
              component="span"
              size="large"
              color="primary"
            >
              <Screenshot />
              {`Add Screenshot`}
            </Button>
          </Tooltip>
        </label>{" "}
        <Box m={1}>
          {error && <Toast severity="error">{error.message}</Toast>}
          {success && <Toast severity="success">{success.message}</Toast>}
        </Box>
      </Paper>
    </>
  );
};

export default MedicalScreenshot;
