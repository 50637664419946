import { useEffect, useState, ChangeEvent, useCallback } from "react";
import { Helmet } from "react-helmet";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchEmployees,
  selectProcesses,
  removeEmployee,
} from "../process/processSlices";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import { NavLink as RouterLink } from "react-router-dom";

import {
  changePageTitle,
  selectPreference,
} from "../preferences/preferencesSlice";
import {
  IconButton,
  Stack,
  Button,
  Typography,
  Tooltip,
  Box,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  Accordion,
  AccordionDetails,
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
  TableRow,
} from "@mui/material";
import {
  Add,
  Edit,
  Delete,
  ExpandMore,
  Search,
  SelectAll,
  Remove,
} from "@mui/icons-material";

import {
  StyledTableCell,
  StyledTableCellSudan,
  StyledTableRow,
} from "../../styles/tableStyles";
import TableSkeleton from "../../components/Layout/TableSkeleton";
import Paging from "../../components/Layout/Paging";
import Toast from "../../components/Layout/Toast";
import { useTranslation } from "react-i18next";
import { usePDF } from "@react-pdf/renderer";
import { selectAuth } from "../auth/authSlice";
import { AuthUser, ClientTypes } from "../auth/types/authType";
import { EmbassyList } from "../../components/reports/embassy/EmbassyList";
import { Employee, EmployeeStatus } from "./types/processTypes";
import { CompanyFilter } from "../../components/filter/CompanyFilter";
import { StyledAccordionSummary } from "../../styles/componentStyled";
import { Company } from "../setups/types/setupTypes";
import { FingerPrintList } from "../../components/reports/labour/FingerPrintList";
import _ from "lodash";
import { EmployeesReportsMenu } from "./components/EmployeesReportsMenu";
import { getAmharicCalendarFormatted } from "../../utils/calendarUtility";
import { CorrectionLetter } from "../../components/reports/labour/CorrectionLetter";
import ExportToExcel from "./components/ExportToExcel";
import { selectSetups } from "../setups/setupSlices";

export const Employees = () => {
  const dispatch = useAppDispatch();
  const { t: te } = useTranslation("enums");
  const theme = useTheme();
  const {
    employeesWithCount: { employees, totalCount },
    loading,
    success,
    error,
  } = useAppSelector(selectProcesses);
  const { user } = useAppSelector(selectAuth);
  const { searchText } = useAppSelector(selectPreference);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [selectCandidates, setSelectCandidates] = useState<boolean[]>([false]);
  const [open, setOpen] = useState(false);
  const [filterExpanded, setFilterExpanded] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const [employeesForFilter, setEmployeesForFilter] = useState<Employee[]>([]);
  const { selectedCompany } = useAppSelector(selectSetups);
  const [company, setCompany] = useState<Company>();
  const [status, setStatus] = useState(EmployeeStatus.all);
  const onChangeStatus = (value: ChangeEvent<HTMLInputElement>) => {
    setStatus(value.target.value as EmployeeStatus);
  };
  const [selectAll, setSelectAll] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [searchValue2, setSearchValue2] = useState("");

  const handleChange = (v: string) => {
    setSearchValue(v);
    debounce(v);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounce = useCallback(
    _.debounce((_searchVal: string) => {
      //dispatch(setSearchText(_searchVal));
      setSearchValue2(_searchVal);
    }, 1000),
    []
  );
  useEffect(() => {
    filterList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue2, status, company]);

  const [letterAmharicDate, setLetterAmharicDate] = useState(
    getAmharicCalendarFormatted(new Date(), "/")
  );
  const [letterNumber, setLetterNumber] = useState("");
  const [typeOfCorrection, setTypeOfCorrection] = useState("name");
  const [reason, setReason] = useState("");

  const asharaList = FingerPrintList({
    employees: selectedEmployees,
    user: user as AuthUser,
    letterDate: letterAmharicDate as string,
    letterNumber: letterNumber as string,
  });
  const [instanceAshara, updateInstanceAshara] = usePDF({
    document: asharaList,
  });

  const correctionLetter = CorrectionLetter({
    employees: selectedEmployees,
    user: user as AuthUser,
    letterDate: letterAmharicDate as string,
    letterNumber: letterNumber as string,
    typoOfCorrection: typeOfCorrection,
    reason: reason,
  });
  const [instanceCorrectionLetter, updateInstanceCorrectionLetter] = usePDF({
    document: correctionLetter,
  });

  const embassyList = EmbassyList({
    employees: selectedEmployees,
    user: user as AuthUser,
  });
  const [instance, updateInstance] = usePDF({
    document: embassyList,
  });

  const handleClickOpen = (id: string) => {
    setSelectedEmployeeId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const DeleteEmployee = () => {
    setOpen(false);
    dispatch(removeEmployee(selectedEmployeeId));
  };

  const ChangeExpanded = () => {
    if (!filterExpanded) {
      ShowAll();
    }
    setFilterExpanded(!filterExpanded);
  };

  useEffect(() => {
    dispatch(changePageTitle("Employees List"));
    const skipRows = currentPage * rowsPerPage;
    if (filterExpanded) {
      setEmployeesForFilter(
        getFilteredList().slice(skipRows, skipRows + rowsPerPage)
      );
    } else {
      dispatch(
        fetchEmployees({
          searchText,
          skip: skipRows,
          take: rowsPerPage,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchText, currentPage, rowsPerPage]); //

  const ShowAll = () => {
    //refreshList: "refresh",
    dispatch(
      fetchEmployees({
        skip: 0,
        take: 3000,
      })
    );
  };
  function filterList() {
    setEmployeesForFilter(getFilteredList);
  }
  const getFilteredList = function () {
    return employees.filter(function (emp) {
      let found = true;
      //Filter By Status
      if (
        (status as EmployeeStatus) !== EmployeeStatus.all &&
        emp.status !== status
      ) {
        found = false;
      }

      //Filter By Company
      if (found)
        if (company && company._id !== "-1") {
          if (
            !(
              emp.prospects &&
              emp.prospects[0] &&
              emp.prospects[0]._id === company._id
            )
          ) {
            found = false;
          }
        }
      //Filter By Text
      if (found && searchValue.length > 0) {
        const regValue = new RegExp(searchValue, "i");
        if (
          emp.passportNumber?.match(regValue) ||
          emp.givenName?.match(regValue) ||
          emp.surName?.match(regValue) ||
          emp.address?.telephone?.match(regValue) ||
          emp.address?.alternateTelephone?.match(regValue) ||
          emp.visa?.visaNumber?.match(regValue) ||
          emp.visa?.fullName?.match(regValue) ||
          emp.visa?.passportNumber?.match(regValue)
        )
          return true;
        else return false;
      }
      return found;
    });
  };
  useEffect(() => {
    if (employees.length > 0) {
      setEmployeesForFilter(employees.slice(0, rowsPerPage));
    } else {
      setEmployeesForFilter([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees]);
  useEffect(() => {
    setTotal(totalCount as number);
    // const checkBoxes = [...Array(totalCount)].map((e, i) => {
    //   return false;
    // });
    // setSelectCandidates(checkBoxes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount]);

  useEffect(() => {
    const employeeIds: Employee[] = [];
    for (let i = 0; i < totalCount; i++) {
      if (selectCandidates[i] === true)
        employeeIds.push(employees[i] as Employee);
    }
    setSelectedEmployees(employeeIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCandidates]);

  useEffect(() => {
    updateInstance();
    updateInstanceAshara();
    updateInstanceCorrectionLetter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmployees]);
  useEffect(() => {
    updateInstanceAshara();
    updateInstanceCorrectionLetter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [letterAmharicDate, letterNumber]);
  useEffect(() => {
    updateInstanceCorrectionLetter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeOfCorrection, reason]);

  const changeCheckBox = (id: string) => {
    if (!selectedEmployees.find((em) => em._id === id))
      setSelectedEmployees([
        ...selectedEmployees,
        employeesForFilter.find((em) => em._id === id) as Employee,
      ]);
    setEmployeesForFilter(employeesForFilter.filter((em) => em._id !== id));
  };
  const removeSelected = (id: string) => {
    setEmployeesForFilter([
      ...employeesForFilter,
      selectedEmployees.find((em) => em._id === id) as Employee,
    ]);
    setSelectedEmployees(selectedEmployees.filter((em) => em._id !== id));
  };
  const changeSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedAll = event.target.checked;
    setSelectAll(selectedAll);
    if (selectedAll) {
      setSelectedEmployees(employeesForFilter);
      setEmployeesForFilter([]);
    } else {
      setEmployeesForFilter(selectedEmployees);
      setSelectedEmployees([]);
    }
  };
  function printEmbassyList() {
    if (instance && instance.url) {
      const iframe = document.createElement("iframe"); //load content in an iframe to print later
      document.body.appendChild(iframe);

      iframe.style.display = "none";
      iframe.src = instance.url as string;
      iframe.onload = function () {
        setTimeout(function () {
          iframe.focus();
          iframe.contentWindow?.print();
        }, 1);
      };
    }
  }
  function printAsharaList() {
    if (instanceAshara && instanceAshara.url) {
      const iframe = document.createElement("iframe"); //load content in an iframe to print later
      document.body.appendChild(iframe);

      iframe.style.display = "none";
      iframe.src = instanceAshara.url as string;
      iframe.onload = function () {
        setTimeout(function () {
          iframe.focus();
          iframe.contentWindow?.print();
        }, 1);
      };
    }
  }
  function printCorrectionLetter() {
    if (instanceCorrectionLetter && instanceCorrectionLetter.url) {
      const iframe = document.createElement("iframe"); //load content in an iframe to print later
      document.body.appendChild(iframe);

      iframe.style.display = "none";
      iframe.src = instanceCorrectionLetter.url as string;
      iframe.onload = function () {
        setTimeout(function () {
          iframe.focus();
          iframe.contentWindow?.print();
        }, 1);
      };
    }
  }

  // const firstPage = SaudiApplication({
  //   employee: selectedEmployee,
  //   user: user as AuthUser,
  // });
  // const [firstPageInstance, updateFirstPageInstance] = usePDF({
  //   document: firstPage,
  // });
  // function printPdf() {
  //   if (
  //     firstPageInstance &&
  //     firstPageInstance.url &&
  //     selectedEmployee._id &&
  //     selectedEmployee._id.length > 12
  //   ) {
  //     const iframe = document.createElement("iframe"); //load content in an iframe to print later
  //     document.body.appendChild(iframe);

  //     iframe.style.display = "none";
  //     iframe.src = firstPageInstance.url as string;
  //     iframe.onload = function () {
  //       setTimeout(function () {
  //         iframe.focus();
  //         iframe.contentWindow?.print();
  //       }, 1);
  //     };
  //   }
  // }
  // useEffect(() => {
  //   if (
  //     firstPageInstance &&
  //     firstPageInstance.url &&
  //     selectedEmployee._id &&
  //     selectedEmployee._id.length > 12
  //   ) {
  //     printPdf();
  //   }
  // }, [firstPageInstance]);
  // useEffect(() => {
  //   if (selectedEmployee._id && selectedEmployee._id.length > 12) {
  //     updateFirstPageInstance();
  //   }
  // }, [selectedEmployee]);
  // const printFirstPage = (employeeId: string) => {
  //   setSelectedEmployee(
  //     employeesForFilter.find((em) => em._id === employeeId) as Employee
  //   );
  // };

  return (
    <>
      <Helmet>
        <title>Employees | EnjazPlus</title>
      </Helmet>
      <Box
        sx={{
          m: { xs: 0, md: 1 },
          px: { xs: 0, md: 2 },
        }}
      >
        <>
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              justifyItems="center"
            >
              <Tooltip title="Add New Employee">
                <Button
                  color="secondary"
                  variant="contained"
                  component={RouterLink}
                  to={`/app/employee/${Math.random()
                    .toString(36)
                    .slice(2, 12)}`}
                >
                  <Add />
                </Button>
              </Tooltip>

              {/* <Tooltip title="Print Ashara List">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={printAsharaList}
                  size="large"
                >
                  <Print /> Ashara List
                </Button>
              </Tooltip> */}
              {/* <Tooltip title="Print Embassy List">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={printEmbassyList}
                  size="large"
                >
                  <Print /> Embassy List
                </Button>
              </Tooltip> */}
              {selectedEmployees && selectedEmployees.length > 0 && (
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-end"
                  justifyItems="center"
                  sx={{
                    "& > :not(style)": {
                      m: 0,
                      ml: 1,
                    },
                  }}
                >
                  {/* {user &&
                    user.roles &&
                    user.roles.some(
                      (r) => r.displayName === "EmbassyReports"
                    ) && <></>} */}
                  <ExportToExcel
                    selectedEmployees={selectedEmployees}
                    company={selectedCompany as Company}
                    user={user as AuthUser}
                  />

                  <EmployeesReportsMenu
                    printEmbassyList={printEmbassyList}
                    printAsharaList={printAsharaList}
                    printCorrectionLetter={printCorrectionLetter}
                    setLetterAmharicDate={setLetterAmharicDate}
                    setLetterNumber={setLetterNumber}
                    setTypeOfCorrection={setTypeOfCorrection}
                    setReason={setReason}
                  />
                </Stack>
              )}
            </Stack>
            {selectedEmployees && selectedEmployees.length > 0 && (
              <Accordion expanded>
                <StyledAccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <SelectAll />
                  <Typography variant="h6" component="div">
                    Selected Employees
                  </Typography>
                </StyledAccordionSummary>

                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <StyledTableRow>
                          <StyledTableCellSudan>Deselect</StyledTableCellSudan>
                          <StyledTableCellSudan>S.No</StyledTableCellSudan>
                          <StyledTableCellSudan>Photo</StyledTableCellSudan>
                          <StyledTableCellSudan>Name</StyledTableCellSudan>
                          <StyledTableCellSudan>
                            Passport No.
                          </StyledTableCellSudan>

                          <StyledTableCellSudan>Visa No.</StyledTableCellSudan>
                          {/* <StyledTableCellSudan>Employer</StyledTableCellSudan> */}
                          <StyledTableCellSudan>
                            Application No.
                          </StyledTableCellSudan>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {selectedEmployees.map((row, index) => (
                          <TableRow key={row._id}>
                            <StyledTableCellSudan width="2%">
                              <Tooltip title="Deselect">
                                <IconButton
                                  color="secondary"
                                  onClick={() =>
                                    removeSelected(row._id as string)
                                  }
                                  size="large"
                                >
                                  <Remove />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCellSudan>
                            <StyledTableCellSudan
                              width="2%"
                              sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 900,
                              }}
                              scope="row"
                            >
                              {currentPage * rowsPerPage + index + 1}
                            </StyledTableCellSudan>
                            <StyledTableCellSudan width="4%">
                              <Avatar
                                component={RouterLink}
                                alt="avatar"
                                src={row ? row.facePhoto : ""}
                                sx={{
                                  cursor: "pointer",
                                  height: 30,
                                  width: 30,
                                }}
                                to={`/app/employee/${row._id}`}
                              />
                            </StyledTableCellSudan>
                            <StyledTableCellSudan
                              sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 900,
                              }}
                            >
                              {row.givenName + " " + row.surName}
                            </StyledTableCellSudan>
                            <StyledTableCellSudan
                              sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 900,
                              }}
                            >
                              {row.passportNumber}
                            </StyledTableCellSudan>

                            <StyledTableCellSudan
                              sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 900,
                              }}
                            >
                              {row.visa?.visaNumber}
                            </StyledTableCellSudan>

                            {/* <StyledTableCellSudan
                              sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 900,
                              }}
                            >
                              {row.visa?.passportNumber && row.visa?.fullName
                                ? `${row.visa?.passportNumber} - ${row.visa?.fullName}`
                                : ""}
                            </StyledTableCellSudan> */}
                            <StyledTableCellSudan
                              sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 900,
                              }}
                            >
                              {row.embassyProcess &&
                              row.embassyProcess.enjazNumber
                                ? `${row.embassyProcess.enjazNumber}`
                                : ""}
                            </StyledTableCellSudan>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            )}
            <Accordion expanded={filterExpanded} onChange={ChangeExpanded}>
              <StyledAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Search />
                <Typography variant="h6" component="div">
                  Search or Filter List
                </Typography>
              </StyledAccordionSummary>

              <AccordionDetails>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item md={3} xs={12}>
                    <CompanyFilter
                      companyType={ClientTypes.ForeignAgent}
                      setCompany={setCompany}
                      includeAll={true}
                    />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <TextField
                      id="statuses"
                      select
                      defaultValue="all"
                      label={"By Current Status"}
                      onChange={onChangeStatus}
                      value={status}
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ mt: 1 }}
                    >
                      {Object.keys(EmployeeStatus)
                        .filter((st) => st !== EmployeeStatus.selected)
                        .map((option) => (
                          <MenuItem key={option} value={option as string}>
                            {te(option.toLowerCase())}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="filterByText"
                      label="Search By Passport/Name/Telephone/Visa/Employer"
                      onChange={(e) => handleChange(e.target.value)}
                      value={searchValue}
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ mt: 1 }}
                    />
                  </Grid>
                  {/* <Grid item md={3} xs={12}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={filterList}
                      size="large"
                    >
                      <ShowChart /> Show
                    </Button>
                  </Grid> */}
                </Grid>
              </AccordionDetails>
            </Accordion>{" "}
          </>

          <Grid container justifyContent="center" sx={{ mt: 1 }}>
            <TableContainer component={Paper}>
              <Table size="medium" aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">Select</StyledTableCell>
                    <StyledTableCell>S.No</StyledTableCell>
                    <StyledTableCell>Photo</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Passport No.</StyledTableCell>
                    <StyledTableCell align="center">
                      Foreign Office
                    </StyledTableCell>
                    <StyledTableCell>Visa No.</StyledTableCell>
                    <StyledTableCell>Employer</StyledTableCell>
                    <StyledTableCell>Application No.</StyledTableCell>
                    <StyledTableCell>Labour Id</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell align="center">Actions</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {loading === "pending" ? (
                    <TableSkeleton numRows={5} numColumns={10} />
                  ) : employeesForFilter.length === 0 ? (
                    <StyledTableRow key={"noData"}>
                      <StyledTableCell colSpan={10} align="center">
                        <Typography variant="h5" color="secondary">
                          No Employees Found...
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    employeesForFilter.map((row, index) => (
                      <StyledTableRow key={row._id}>
                        <StyledTableCell width="2%">
                          <Switch
                            checked={
                              selectCandidates[index]
                                ? selectCandidates[index]
                                : false
                            }
                            onChange={() => changeCheckBox(row._id as string)}
                            inputProps={{ "aria-label": "controlled" }}
                            name={index.toString()}
                            color="secondary"
                          />

                          {/* <Checkbox
                            checked={
                              selectCandidates[index]
                                ? selectCandidates[index]
                                : false
                            }
                            onChange={(e) =>
                              changeCheckBox(e, index, row._id as string)
                            }
                            inputProps={{ "aria-label": "controlled" }}
                            name={index.toString()}
                            color="secondary"
                          /> */}
                        </StyledTableCell>
                        <StyledTableCell
                          width="2%"
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 700,
                          }}
                          component="th"
                          scope="row"
                        >
                          {currentPage * rowsPerPage + index + 1}
                        </StyledTableCell>
                        <StyledTableCell width="4%">
                          <Avatar
                            component={RouterLink}
                            alt="avatar"
                            src={row ? row.facePhoto : ""}
                            sx={{
                              cursor: "pointer",
                              height: 40,
                              width: 40,
                            }}
                            to={`/app/employee/${row._id}`}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          width="20%"
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 700,
                          }}
                        >
                          {row.givenName + " " + row.surName}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            color: theme.palette.secondary.main,
                            fontWeight: 700,
                          }}
                        >
                          {row.passportNumber}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            color: theme.palette.primary.main,
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          {row.prospects && row.prospects[0]
                            ? row.prospects[0].displayName.slice(0, 10) + "..."
                            : "-"}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            color: theme.palette.secondary.main,
                            fontWeight: 700,
                          }}
                        >
                          {row.visa?.visaNumber}
                        </StyledTableCell>
                        <StyledTableCell
                          width="20%"
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 700,
                          }}
                        >
                          {row.visa?.passportNumber && row.visa?.fullName
                            ? `${row.visa?.passportNumber} - ${row.visa?.fullName}`
                            : ""}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            color: theme.palette.secondary.main,
                            fontWeight: 700,
                          }}
                        >
                          {row.embassyProcess && row.embassyProcess.enjazNumber
                            ? `${row.embassyProcess.enjazNumber}`
                            : ""}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 700,
                          }}
                        >
                          {row.additionalData?.labourId}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ color: "#FFA500", fontWeight: 700 }}
                        >
                          {te(row.status as string)}
                        </StyledTableCell>

                        <StyledTableCell>
                          <Stack direction="row" alignItems="center">
                            <IconButton
                              color="primary"
                              component={RouterLink}
                              to={"/app/employee/" + row._id}
                              size="large"
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              color="secondary"
                              onClick={() => handleClickOpen(row._id as string)}
                              size="large"
                            >
                              <Delete />
                            </IconButton>
                            {/* <EmployeesRowMenu
                              row={row}
                              printFirstPage={printFirstPage}
                            /> */}
                          </Stack>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <br />
          {success && <Toast severity="success">{success.message}</Toast>}
          {error && <Toast severity="error">{error.message}</Toast>}
          {employees.length > 0 && (
            <Stack spacing={1}>
              <FormControlLabel
                style={{ marginBottom: "0" }}
                control={
                  <Switch
                    checked={selectAll}
                    onChange={(e) => changeSelectAll(e)}
                    inputProps={{ "aria-label": "controlled" }}
                    name="selectAll"
                    color="secondary"
                  />
                }
                label="Select All"
              />
              <Paging
                total={total}
                rowsPerPage={rowsPerPage}
                currentPage={currentPage}
                setRowsPerPage={setRowsPerPage}
                setCurrentPage={setCurrentPage}
              />
              <Typography variant="body2" color="textPrimary" component="div">
                Total Number of Employees: {total}
              </Typography>
            </Stack>
          )}
        </>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This data will be deleted permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button onClick={DeleteEmployee}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
