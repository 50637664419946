import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Avatar,
  Button,
  IconButton,
  Stack,
  Tooltip,
  FormControlLabel,
  Switch,
  Fab,
  Typography,
} from "@mui/material";
import Image from "mui-image";

import { Add, Delete } from "@mui/icons-material";
import Toast from "../../components/Layout/Toast";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { resetSelectedMedicalPayment, selectProcesses } from "./processSlices";
import { MedicalDetail, MedicalPayment } from "./types/processTypes";
import { changePageTitle } from "../preferences/preferencesSlice";
import { FormikTextField } from "../../components/Layout/FormikTextField";
import { Form, Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { createMedicalPaymentSchema } from "./validation";
import MedicalPassport from "./components/MedicalPassport";
import MedicalScreenshot from "./components/MedicalScreenshot";

export const MedicalEntry = () => {
  const { id } = useParams() as {
    id: string;
  };
  const { t } = useTranslation();
  const { t: te } = useTranslation("enums");
  const [lines, setLines] = useState<MedicalDetail[]>([]);
  const [screenshot, setScreenshot] = useState("");
  const [showWafidLink, setShowWafidLink] = useState(false);
  const dispatch = useAppDispatch();
  const { selectedMedicalPayment, error, success } =
    useAppSelector(selectProcesses);

  useEffect(() => {
    dispatch(changePageTitle(`Medical Entry`));

    if (id && id !== "0") {
      // dispatch(getMedicalPayment(id));
    } else {
      resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (
      selectedMedicalPayment &&
      selectedMedicalPayment.details &&
      selectedMedicalPayment.details?.length > 0
    )
      setLines(selectedMedicalPayment.details as MedicalDetail[]);
  }, [selectedMedicalPayment]);

  function resetFields() {
    dispatch(resetSelectedMedicalPayment());
  }
  function addLine(passportUrl: string) {
    setLines([...lines, { byLinkOrName: passportUrl }]);
  }
  function addScreenshot(passportUrl: string) {
    setScreenshot(passportUrl);
    // setLines([...lines, { byLinkOrName: passportUrl }]);
  }
  function addLinkLine() {
    setLines([...lines, { byLinkOrName: "passportUrl" }]);
  }
  function removeLine(linkName: string) {
    setLines(lines.filter((l) => l.byLinkOrName !== linkName));
  }
  useEffect(() => {
    if (lines) {
    }
  }, [lines]);

  return (
    <Box
      sx={{
        m: { xs: 0, md: 1 },
        px: { xs: 0, md: 2 },
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={selectedMedicalPayment as MedicalPayment}
        validationSchema={createMedicalPaymentSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          if (lines) {
            values = {
              ...values,
              details: lines as [MedicalDetail],
            };
          }
          console.log(values);
          //dispatch(createMedicalPayment(values));
        }}
      >
        {(props: FormikProps<MedicalPayment>) => (
          <Form>
            <Stack sx={{ p: 2 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <MedicalPassport key={1} uploadType="line" addLine={addLine} />
                <FormControlLabel
                  style={{ marginBottom: "0" }}
                  control={
                    <Switch
                      checked={showWafidLink}
                      onChange={() => setShowWafidLink(!showWafidLink)}
                      inputProps={{ "aria-label": "controlled" }}
                      name="addWafidLink"
                      color="secondary"
                    />
                  }
                  label="Add Link"
                />
              </Stack>
              {showWafidLink && (
                <Stack direction="row" alignItems="center" spacing={0}>
                  <FormikTextField
                    formikKey="byLinkOrName"
                    label="Wafid Link"
                  />

                  <Button
                    color="primary"
                    variant="contained"
                    onClick={addLinkLine}
                    sx={{ mt: 1 }}
                  >
                    <Add />{" "}
                  </Button>
                </Stack>
              )}

              <Grid container justifyContent="start" sx={{ mt: 1 }} spacing={1}>
                {lines &&
                  lines.map((row, index) => (
                    <Grid
                      container
                      item
                      lg={3}
                      md={4}
                      sm={6}
                      xs={12}
                      direction="column"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      key={index}
                    >
                      {/* <Avatar
                        alt="indexNum"
                        src={""}
                        sx={{
                          height: "30px",
                          width: "30px",
                          backgroundColor: "#008000",
                        }}
                      >
                      </Avatar> */}
                      <Typography variant="h4" color="primary">
                        #{index + 1}
                      </Typography>

                      {/* <Image
                        src={row ? (row.byLinkOrName as string) : ""}
                        width={200}
                        height={200}
                        shift="top"
                        distance="2rem"
                        shiftDuration={320}
                        style={{
                          borderRadius: "10px",
                        }}
                      /> */}
                      <Avatar
                        alt="avatar"
                        variant="rounded"
                        src={row ? row.byLinkOrName : ""}
                        sx={{
                          height: "200px",
                          width: "200px",
                          transition: "transform .2s",
                          objectFit: "fill",
                          ":hover": {
                            transform: "scale(2,2)",
                          },
                          ":active": { transform: "scale(2,2)" },
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          marginTop: 0,
                        }}
                      >
                        {row.byLinkOrName && (
                          <>
                            <Box component="div">
                              <Tooltip
                                title="Remove Passport"
                                aria-label="icon-button-file-passport"
                              >
                                <Fab component="span" color="error">
                                  <IconButton
                                    color="inherit"
                                    edge="start"
                                    onClick={() =>
                                      removeLine(row.byLinkOrName as string)
                                    }
                                    size="medium"
                                    sx={{
                                      margin: "auto",
                                      width: "auto",
                                    }}
                                  >
                                    <Delete />
                                  </IconButton>
                                </Fab>
                              </Tooltip>
                            </Box>
                          </>
                        )}
                      </div>

                      {/* <TableContainer component={Paper}>
                        <Table size="small" aria-label="simple table">
                          <TableHead sx={{ backgroundColor: "transparent" }}>
                            <TableRow>
                              <TableCell>S.No.</TableCell>
                      <TableCell>Link</TableCell>
                      
                      <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <StyledTableRow key={index}>
                              <StyledTableCell>{index + 1}</StyledTableCell>
                              <StyledTableCell></StyledTableCell>

                              <StyledTableCell>
                                <Button
                                  color="secondary"
                                  onClick={() =>
                                    removeLine(row.byLinkOrName as string)
                                  }
                                  size="large"
                                >
                                  <Delete />
                                </Button>
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer> */}
                    </Grid>
                  ))}
              </Grid>

              {lines && lines.length > 0 && (
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="start"
                  sx={{
                    mt: 2,
                  }}
                >
                  <MedicalScreenshot
                    key={2}
                    uploadType="screenshot"
                    addScreenshot={addScreenshot}
                  />
                  <Image
                    src={screenshot ? (screenshot as string) : ""}
                    width={200}
                    height={200}
                    shift="top"
                    distance="2rem"
                    shiftDuration={320}
                    style={{
                      borderRadius: "10px",
                    }}
                  />

                  {/* <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={!props.isValid}
                  >
                    <Save /> Attach Screenshot
                  </Button> */}
                </Stack>
              )}
            </Stack>
            <br />
            {success && <Toast severity="success">{success.message}</Toast>}
            {error && <Toast severity="error">{error.message}</Toast>}
          </Form>
        )}
      </Formik>
      {error && <Toast severity="error">{error.message}</Toast>}
    </Box>
  );
};
